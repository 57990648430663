/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('jquery/jquery.cookie',[
    'jquery',
    'js-cookie/cookie-wrapper'
], function () {

});

