/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_PaypalCaptcha/js/model/skipRefreshCaptcha',['ko'], function (ko) {
    'use strict';

    return {
        skip: ko.observable(false)
    };
});

