/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_Translation/js/mage-translation-dictionary',[
    'text!js-translation.json'
], function (dict) {
    'use strict';

    return JSON.parse(dict);
});

