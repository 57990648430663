/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_Ui/js/model/messageList',[
    './messages'
], function (Messages) {
    'use strict';

    return new Messages();
});

